import React, { lazy } from 'react'

const TimesheetManagementListing = lazy(() =>
  import('./TimesheetManagement/TimesheetManagementListing').then(module => ({
    default: module.TimesheetManagementListing,
  }))
)


const HomeNotification = lazy(() =>
  import('./Notification/HomeNotification').then(module => ({
    default: module.HomeNotification,
  }))
)

const HomeNotificationDetail = lazy(() =>
  import('./Notification/HomeNotificationDetail').then(module => ({
    default: module.HomeNotificationDetail,
  }))
)
const HomeNotificationDataChangesDetail = lazy(() =>
  import('./Notification/HomeNotificationDataChangesDetail').then(module => ({
    default: module.HomeNotificationDataChangesDetail,
  }))
)
// const HomeTodo = lazy(() =>
//   import('./Todo/Todo').then(module => ({
//     default: module.Todo,
//   }))
// )
const TodoDetail = lazy(() =>
  import('./Todo/TodoDetail').then(module => ({
    default: module.TodoDetail,
  }))
)

const TodoRequisitionDetail = lazy(() =>
  import('./Todo/TodoRequisitionDetail').then(module => ({
    default: module.TodoRequisitionDetail,
  }))
)



const TodoProjectExpenseDetail = lazy(() =>
  import('./Todo/TodoProjectExpenseDetail').then(module => ({
    default: module.TodoProjectExpenseDetail,
  }))
)


const HomeBulletin = lazy(() =>
  import('./Bulletin/HomeBulletin').then(module => ({
    default: module.HomeBulletin,
  }))
)

const HomeCalendar = lazy(() =>
  import('./Calendar/HomeCalendar').then(module => ({
    default: module.HomeCalendar,
  }))
)


const TodoCalendar = lazy(() =>
  import('./Todo/TodoCalendar').then(module => ({
    default: module.TodoCalendar,
  }))
)
const TodoClaim = lazy(() =>
  import('./Todo/TodoClaim').then(module => ({
    default: module.TodoClaim,
  }))
)
const TodoRequisition = lazy(() =>
  import('./Todo/TodoRequisition').then(module => ({
    default: module.TodoRequisition,
  }))
)

const TodoTimeOff = lazy(() =>
  import('./Todo/TodoTimeOff').then(module => ({
    default: module.TodoTimeOff,
  }))
)
const TodoChangeShift = lazy(() =>
  import('./Todo/TodoChangeShift').then(module => ({
    default: module.TodoChangeShift,
  }))
)
const TodoProjectExpense = lazy(() =>
  import('./Todo/TodoProjectExpense').then(module => ({
    default: module.TodoProjectExpense,
  }))
)
const ToDoDataChanges = lazy(() =>
  import('./Todo/TodoDataChanges').then(module => ({
    default: module.ToDoDataChanges,
  }))
)
const ToDoDataChangesDetails = lazy(() =>
  import('./Todo/TodoDataChangesDetails').then(module => ({
    default: module.ToDoDataChangesDetails,
  }))
)

const HomeRoutes = [
  {
    props: { exact: true, path: '/TimesheetManagementListing' },
    component: <TimesheetManagementListing />,
  },
  {
    props: { exact: true, path: '/home/todo/claim' },
    component: <TodoClaim />,
  },
  {
    props: { exact: true, path: '/home/todo/projectexpense' },
    component: <TodoProjectExpense />,
  },

  {
    props: { exact: true, path: '/home/todo/requisition' },
    component: <TodoRequisition />,
  },
  {
    props: { exact: true, path: '/home/todo/timeoff' },
    component: <TodoTimeOff />,
  },
  {
    props: { exact: true, path: '/home/todo/changeshift' },
    component: <TodoChangeShift />,
  },


 
  {
    props: { exact: true, path: '/home/todo/tododetail' },
    component: <TodoDetail />,
  },
  {
    props: { exact: true, path: '/home/todo/todoprojectexpensedetail' },
    component: <TodoProjectExpenseDetail />,
  },

  {
    props: { exact: true, path: '/home/todo/datachanges' },
    component: <ToDoDataChanges />,
  },
  {
    props: { exact: true, path: '/home/todo/datachanges/details' },
    component: <ToDoDataChangesDetails />,
  },

  //Notification
  {
    props: { exact: true, path: '/home/notification' },
    component: <HomeNotification />,
  },
  {
    props: { exact: true, path: '/home/HomeNotificationDetail' },
    component: <HomeNotificationDetail />,
  },
  {
    props: { exact: true, path: '/home/HomeNotificationDataChangesDetail' },
    component: <HomeNotificationDataChangesDetail />,
  },

  //Bulletin
  {
    props: {
      exact: true,
      path: '/home/bulletin',
    },
    component: <HomeBulletin />,
  },

  //Calendar

  {
    props: {
      exact: true,
      path: '/home/calendar',
    },
    component: <HomeCalendar />,
  },

  {
    props: {
      exact: true,
      path: '/home/todo/calendar',
    },
    component: <TodoCalendar />,
  },

 
]

export default HomeRoutes
